<template>
  <div>
      <Menubar :model="itemz">
            <template #start>
               <img :src="require(`../assets/ploutus-logo-xs.png`)" class="logo-img" alt="logo">
            </template>
            <template item="{item}">
                <!-- <a :href="item.url">{{item.label}}</a> -->
                <router-link :to="item.url" :target="item.target">{{item.label}}</router-link>
            </template>
            <template #end>
                <Button type="button"  @click="Logout" class="p-button-outlined p-button-secondary">
                    <i class="pi pi-sign-out logout-btn-icon"></i>
                    <span class="p-ml-2 p-mr-2 p-text-bold">Logout</span>
                    <Avatar :image=image class="p-mr-2" shape="circle" />
                </Button>
            </template>
        </Menubar>
      <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { useRouter } from 'vue-router';
import {ref, onBeforeMount} from 'vue';
// import firebase from 'firebase';
import Menubar from 'primevue/menubar';
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';
export default {
    components: {Menubar, Button, Avatar,},
    setup(){
        const router = useRouter();
        // const db = firebase.firestore();
        const ploType = ref("");
        const ploAdm = ref();
        const ploMgr = ref();
        const ploNoStf = ref();
        ploType.value= localStorage.getItem("ploType");
        // console.log(ploType.value);
    if(ploType.value !== "adm" && ploType.value !== "mgr" && ploType.value !== "stf"){
        return router.replace('/pin')
    }
    else{
        ploType.value === "adm" ? ploAdm.value = true : ploAdm.value = false
        ploType.value === "mgr" ? ploMgr.value = true : ploMgr.value = false
        ploType.value !== "stf" ? ploNoStf.value = true : ploNoStf.value = false
        const itemz = ref([
            {
               label:'Home',
               icon:'pi pi-fw pi-home',
               url:'/',
               
            },
            {
               label:'About',
               icon:'pi pi-fw pi-users',
               url:'/about',
               
            },
            {
               label:'Forms',
               icon:'pi pi-fw pi-file',
               url:'/forms',
               
            },
            {
               label:'Videos',
               icon:'pi pi-fw pi-video',
               url:'/videos',
               
            },
            {
               label:'Drive',
               icon:'pi pi-fw pi-inbox',
               url:'https://drive.google.com/drive/folders/1OL2XhccqM_z693tjs2IXEx4PPBplQnxx',
               target:'_blank'
               
            },
            {
               label:'Todos',
               icon:'pi pi-fw pi-book',
               url:'/todos',
               
            },
            {
               label:'Managers',
               icon:'pi pi-fw pi-users',
               url:'/managers',
               visible: ploMgr,
            },
            {
               label:'Manage',
               icon:'pi pi-fw pi-pencil',
               visible: ploAdm,
               items:[
                    {
                        label:'Mangers',
                        icon:'pi pi-fw pi-users',
                        url:'/managers',
                    },
                    {
                        label:'Domains',
                        icon:'pi pi-fw pi-sitemap',
                        url:'/domains',
                    },
                    {
                        label:'Admins',
                        icon:'pi pi-fw pi-user-plus',
                        url:'/admins',
                    }
               ]
            },
            {
               label:'Chat',
               icon:'pi pi-fw pi-comments',
               url:'https://mail.google.com/chat/u/0/',
               target:'_blank'
            },
            {
               label:'Calendar',
               icon:'pi pi-fw pi-calendar',
               url:'https://calendar.google.com/',
               target:'_blank'
            },
            {
               label:'Activity',
               icon:'pi pi-fw pi-exclamation-triangle',
               url:'/activity',
               visible: ploNoStf,
            },
        ]);

        /** */
        
        /** */
        const image = ref("");

        onBeforeMount(()=>{
        // const user = firebase.auth().currentUser;
        // if(user){
        //     // console.log('user');
        // }
        image.value= localStorage.getItem("ploUserImg");
        });

        // const Inactive = () => {
        //     db.collection('user').doc(localStorage.getItem("ploUid")).update({active: false})
        //     console.log('triggered Logout');
        // }

// var t;
// let resetTimer =() => {
            
//             clearTimeout(t);
//             t = setTimeout(Logout, 10000)
            
//         }
        
        // window.onload = resetTimer;
        // DOM Events
        // window.onmousemove = resetTimer;
        // window.onkeypress = resetTimer;
        
        // window.onmousemove = resetTimer;
        // window.onkeypress = resetTimer;
        
        // window.addEventListener("onmousemove", resetTimer)
        // window.addEventListener("onkeypress", resetTimer)

        // window.onload = () =>{
        //     document.addEventListener("onmousemove", resetTimer)
        //     document.addEventListener("onkeypress", resetTimer)
        // }

        // onMounted(()=>{
        //     document.onmousemove = resetTimer;
        //     document.onkeypress = resetTimer;
        //     console.log('loaded');
        // });

        

        

        const Logout = () =>{
            localStorage.setItem("ploLogout", "logout");
            router.replace('/pin')
        // firebase
        //     .auth()
        //     .signOut()
        //     .then(() => console.log("Signed Out"), localStorage.removeItem("ploType"), localStorage.removeItem("ploUserName"), localStorage.removeItem("ploUserEmail"), localStorage.removeItem("ploUserImg"), localStorage.removeItem("ploUid")
        //     )
        //     .catch(err => alert(err.message));
        }
        return{
        image,
        Logout,
        ploType,
        itemz,
        ploAdm,
        ploMgr,
        }
    }
  }
}
</script>

<style>
.p-menubar-end{
    display: flex;
    align-items: center;
}
.icon-font-size{
    font-size: 2rem;
    color: #495057;
}
</style>